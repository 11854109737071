import { Module, VuexModule, getModule } from 'vuex-module-decorators';
import store from '@/store';

@Module({
    store,
    dynamic: true,
    name: 'tracking',
    namespaced: true,
})
class TrackingClass extends VuexModule {
    
    get ALLOW_FUNCTIONAL(): boolean {
        return (window as any).consent?.functional ?? true;
    }

    get ALLOW_STATISTICS(): boolean {
        return (window as any).consent?.statistics ?? true;
    }

    get ALLOW_MARKETING(): boolean {
        return (window as any).consent?.marketing ?? true;
    }
}

export const TrackingModule = getModule(TrackingClass);
